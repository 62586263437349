<template>
    <div class="modal-dialog login_dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="ms_register_img" style="margin-top: 2vh;">
                    <img src="../../assets/images/zain_gray.png" alt="" class="img-fluid" />
                </div>
                <div class="ms_register_form">
                    <form @submit.prevent="Subscrib()">
                        <h2>اشعاري</h2>
                        <!-- <a href="profile.html" class="ms_btn" target="_blank" style="width: 80%;">الغاء الاشتراك</a> -->
                        <a @click="Subscrib()" class="ms_btn pointer" style="width: 80%;">
                            <span class="px-1">إشتراك</span> &nbsp;
                        </a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
   name: 'AppSubscribe',
   setup() {
        const Subscrib = () => {
            window.open("https://dsplp.sd.zain.com/?p=1255773854", "_self");
        }
        return { Subscrib }
    }
}
</script>

<style>

</style>